/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import { useTheme } from '@mui/material/styles';
import { baseUrl } from 'constants';
import Container from 'components/Container';
import axios from 'axios';


const Contact = () => {
    const theme = useTheme();

    const LeftSide = () => {

        const [errorText, setErrorText] = useState("")
        const [csrfToken, setCsrfToken] = useState("")
        const [loading, setLoading] = useState(false)
        const [success, setSuccess] = useState(false)
        const [error, setError] = useState(false)

        function getCookie(name) {
            var cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                var cookies = document.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "")
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        }

        useEffect(() => {
            var token = getCookie('csrftoken')
            setCsrfToken(token)

        }, [])

        const formik = useFormik({
            initialValues: {
                name: '',
                phone_number: '',
                message: '',
            },
            validationSchema: Yup.object({
                name: Yup.string()
                    .max(50, 'Must be 15 characters or less')
                    .required('Required'),
                phone_number: Yup.string()
                    .min(10, 'Must be 10 characters or less')
                    .max(10, 'Must be 10 characters or less')
                    .required('Required'),
                message: Yup.string()
                    .max(1000, 'Must be 10000 characters or less')
                    .required('Required'),
            }),
            onSubmit: values => {
                // alert(JSON.stringify(values, null, 2));
                // var msg = JSON.stringify(values, null, 2);
                // console.log(values)
                handle_submit_query(values)
            },
        });

        const handle_submit_query = (values) => {
            console.log("submitting queyr", csrfToken)
            const { name, phone_number, message } = values
            const url = `${baseUrl}enquiries/create/`
            setLoading(true)
            const token = csrfToken ? csrfToken : "null"
            axios.post(url, {
                name: name,
                phone_number: phone_number,
                message: message,
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': token
                }
            },
            )
                .then(function (response) {
                    setLoading(false)
                    setSuccess(true)
                    console.log("sucess in posting", response);
                })
                .catch(function (error) {
                    setLoading(false)
                    setError(true)
                    console.log("error in posting", error);
                });
        }


        return (
            <Box>
                <Box marginBottom={4}>
                    <Typography variant={'h3'} sx={{ fontWeight: 700 }} gutterBottom>
                        Contact us
                    </Typography>
                    <Typography color="text.secondary">
                        Would you like to be the part of Fosmo's initiatives or would like to know more of what do we do? please reach us.
                    </Typography>
                </Box>
                <Box>
                    {success &&
                        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                            <Alert severity="success">{"Your query has  successfully been submitted"}</Alert>
                        </Box>
                    }
                    {loading &&
                        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </Box>
                    }
                    {error &&
                        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                            <Alert severity="error">{"Error Occured, please try again"}</Alert>
                        </Box>
                    }
                    {!success &&
                        < form noValidate onSubmit={formik.handleSubmit}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        sx={{ height: 54 }}
                                        label="Name"
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        name="name"
                                        fullWidth
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.name && Boolean(formik.errors.name)
                                        }
                                        helperText={
                                            formik.touched.name && formik.errors.name
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        sx={{ height: 54 }}
                                        label="Phone Number"
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        name="phone_number"
                                        fullWidth
                                        value={formik.values.phone_number}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.phone_number && Boolean(formik.errors.phone_number)
                                        }
                                        helperText={formik.touched.phone_number && formik.errors.phone_number}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Message"
                                        multiline
                                        rows={6}
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        name="message"
                                        fullWidth
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.message && Boolean(formik.errors.message)
                                        }
                                        helperText={formik.touched.message && formik.errors.message}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        sx={{ height: 54, minWidth: 150 }}
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="text.secondary">
                                        We'll get back to you in 1-2 business days.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography component="p" variant="body2" align="left">
                                            By clicking on "submit" you agree to our{' '}
                                            <Box
                                                component="a"
                                                href=""
                                                color={theme.palette.text.primary}
                                                fontWeight={'700'}
                                            >
                                                Privacy Policy
                                            </Box>
                                            ,{' '}
                                            <Box
                                                component="a"
                                                href=""
                                                color={theme.palette.text.primary}
                                                fontWeight={'700'}
                                            >
                                                Data Policy
                                            </Box>{' '}
                                            and{' '}
                                            <Box
                                                component="a"
                                                href=""
                                                color={theme.palette.text.primary}
                                                fontWeight={'700'}
                                            >
                                                Cookie Policy
                                            </Box>
                                            .
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>}
                </Box>
            </Box >
        );
    };

    const RightSide = () => {
        return (
            <iframe
                width="100%"
                height="100%"
                frameBorder="0"
                title="map"
                marginHeight={0}
                marginWidth={0}
                scrolling="no"
                src="https://maps.google.com/maps?width=100%&height=100%&hl=en&q=wayanad+muttil&ie=UTF8&t=&z=14&iwloc=B&output=embed"
                style={{
                    minHeight: 300,
                    filter:
                        theme.palette.mode === 'dark'
                            ? 'grayscale(0.5) opacity(0.7)'
                            : 'none',
                }}
            />
        );
    };

    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                overflow: 'hidden',
            }}
        >
            <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
                <Box
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    position={'relative'}
                >
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        width={1}
                        order={{ xs: 2, md: 1 }}
                    >
                        <Container>
                            <LeftSide />
                        </Container>
                    </Box>
                    <Box
                        sx={{
                            flex: { xs: '0 0 100%', md: '0 0 50%' },
                            position: 'relative',
                            maxWidth: { xs: '100%', md: '50%' },
                            minHeight: { xs: 300, md: 600 },
                            order: { xs: 1, md: 2 },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: 1, md: '50vw' },
                                height: '100%',
                                position: 'relative',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'hidden',
                                }}
                            >
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        left: '0%',
                                        width: 1,
                                        height: 1,
                                        position: { xs: 'relative', md: 'absolute' },
                                    }}
                                >
                                    <RightSide />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Divider />
        </Box>
    );
};

export default Contact;
