/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Img1 from '../../../../assets/images/jamalikka_kids_2.jpg'


const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontFamily: "Poppins", fontWeight: 600 }}>
              OUR STORY
            </Typography>
            <Typography component={'p'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>
              The diverse tapestry of human experiences often includes stories of struggle and resilience. Among them lies the narrative of FOSMO (Forum for Old Students of Muslim Orphanage), an influential alumni organisation born from the nurturing grounds of the Wayanad Muslim Orphanage. The primary objective of FOSMO is to offer unwavering support not only to its needy members but also to bolster the very institution that played a pivotal role in shaping their lives—the Wayanad Muslim Orphanage.
              <br />
              <br />
              FOSMO was conceived with the fundamental belief that those who have benefited from the orphanage have a moral obligation to give back. This sentiment resonates deeply, as many alumni understand firsthand the importance of community support and solidarity. In a world often characterised by self-interest, FOSMO emerges as a striking example of empathy and action.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={Img1}
              width={1}
              height={1}
              sx={{
                borderRadius: 2,
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>

    </Box>
  );
};

export default Story;
