import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from "../../constants"

const initialState = {
    data: null,
    isLoading: false,
    error: null,
};

export const getTeams = createAsyncThunk('team/fetchTeam', async () => {
    const response = await axios.get(`${baseUrl}teams/`)
    return response.data;
});

const teamSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTeams.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getTeams.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(getTeams.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
    },
});

export const { } = teamSlice.actions;
export const data = (state) => state.teams.data
export const isLoading = (state) => state.teams.isLoading

export default teamSlice.reducer;
