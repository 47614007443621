import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import NavItem from './components/NavItem';
import Logo from "../../../../../../assets/images/logo_2.png"

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    home: homePage,
    properties: propertyListPage,
    about: aboutPage,
    contact: contactPage,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={Logo}

            // src={
            //   mode === 'light'
            //     ? 'https://assets.maccarianagency.com/the-front/logos/logo.svg'
            //     : 'https://assets.maccarianagency.com/the-front/logos/logo-negative.svg'
            // }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'Home'} href={"/"} items={homePage} />
        </Box>
        {/* <Box>
          <NavItem title={'Institutions'} href={"/"} items={propertyListPage} />
        </Box> */}
        <Box>
          <NavItem title={'About'} href={"/about"} items={aboutPage} />
        </Box>
        <Box>
          <NavItem title={'Contact'} href={"/contact"} items={contactPage} />
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
