import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

import Logo from "../../../../assets/images/logo_2.png"


const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component="a"
            href="/"
            title="Fosmo Wayanad"
            width={80}
          >
            <Box
              component={'img'}
              src={Logo}
              // src={
              //   mode === 'light'
              //     ? 'https://assets.maccarianagency.com/the-front/logos/logo.svg'
              //     : 'https://assets.maccarianagency.com/the-front/logos/logo-negative.svg'
              // }
              height={1}
              width={1}
            />
          </Box>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            <Box >
              <Box marginTop={1} >
                <IconButton
                  aria-label="youtube"
                  size={'small'}
                  color={'primary'}
                  href='https://www.youtube.com/@FosmoTeamwmo'
                  target="_blank"
                >
                  <YouTubeIcon />
                </IconButton>
                <IconButton
                  aria-label="facebook"
                  href='https://www.facebook.com/share/ymWetyV9VqJPfyP5/'
                  size={'small'}
                  color={'primary'}
                >
                  <FacebookIcon />
                </IconButton>

                <IconButton
                  aria-label="instagram"
                  size={'small'}
                  href='https://www.instagram.com/fosmo_team_wmo_/'
                  color={'primary'}

                >
                  <InstagramIcon />
                </IconButton>

              </Box>
            </Box>

            {/* <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/"
                color="text.primary"
                variant={'subtitle2'}
              >
                Home
              </Link>
            </Box> */}
            {/* <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/docs/introduction"
                color="text.primary"
                variant={'subtitle2'}
              >
                Documentation
              </Link>
            </Box> */}
            {/* <Box marginTop={1}>
              <Button
                variant="outlined"
                color="primary"
                component="a"
                target="blank"
                href="https://mui.com/store/items/the-front-landing-page/"
                size="small"
              >
                Purchase now
              </Button>
            </Box> */}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Link href="https://www.fosmowayanad.org/admin/">
          <Typography
            href={"/admin"}
            align={'center'}
            variant={'subtitle2'}
            color="text.secondary"
            gutterBottom
          >
            FOSMO ADMIN
          </Typography>
        </Link>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; Fosmo. 2024. All Rights Reserved
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
