import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Page from 'components/Page';
import Main from 'layouts/Main';
import Form from "./form"
// import Form from "./test"

import Container from 'components/Container';


const validationSchema = yup.object({
    fullName: yup
        .string()
        .trim()
        .min(2, 'Please enter a valid name')
        .max(50, 'Please enter a valid name')
        .required('Please specify your first name'),
    email: yup
        .string()
        .trim()
        .email('Please enter a valid email address')
        .required('Email is required.'),
    bio: yup
        .string()
        .trim()
        .max(500, 'Should be less than 500 chars'),
    country: yup
        .string()
        .trim()
        .min(2, 'Please enter a valid name')
        .max(80, 'Please enter a valid name')
        .required('Please specify your country name'),
    city: yup
        .string()
        .trim()
        .min(2, 'Please enter a valid name')
        .max(80, 'Please enter a valid name')
        .required('Please specify your city name'),
    address: yup
        .string()
        .required('Please specify your address')
        .min(2, 'Please enter a valid address')
        .max(200, 'Please enter a valid address'),
});

const General = () => {
    const initialValues = {
        fullName: '',
        bio: '',
        email: '',
        country: '',
        city: '',
        address: '',
    };

    const onSubmit = (values) => {
        return values;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });

    return (
        <Main>
            <Page>
                <Container>
                    <Form />
                </Container>
            </Page>
        </Main>
    );
};

export default General;
