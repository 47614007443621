/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const WhoWeAre = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontFamily: "Poppins", fontWeight: 600 }}>
              ROOTS OF FOSMO
            </Typography>
            <Typography component={'p'} color={'text.secondary'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>

              To grasp the essence of FOSMO, we must first acknowledge the unique environment fostered by the Wayanad Muslim Orphanage. Established with the noble intent of providing shelter, education, and emotional support to children from underprivileged backgrounds, the orphanage has become a sanctuary for countless young lives. The alumni of this institution, having overcome personal and societal challenges, found themselves united under the banner of FOSMO.
            </Typography>
            <br />
            <Typography component={'p'} color={'text.secondary'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>
              FOSMO was conceived with the fundamental belief that those who have benefited from the orphanage have a moral obligation to give back. This sentiment resonates deeply, as many alumni understand firsthand the importance of community support and solidarity. In a world often characterized by self-interest, FOSMO emerges as a striking example of empathy and action.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontFamily: "Poppins", fontWeight: 600 }}>
              OBJECTIVES AND INITIATIVES
            </Typography>
            <Typography component={'p'} color={'text.secondary'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>

              At its core, FOSMO seeks to provide aid to its members who are facing hardships. The organization stands as a lifeline for these individuals, offering not just financial assistance but also emotional support and encouragement. The alumni network is a source of strength; its members come together to share resources, job opportunities, and guidance, thus reinforcing the once-fragile bonds formed during their time at the orphanage.
            </Typography>
            <br />
            <Typography component={'p'} color={'text.secondary'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>
              Moreover, FOSMO takes its mission beyond individual support. Recognizing the importance of sustainability and legacy, the organization actively works to enhance the operational capabilities of the Wayanad Muslim Orphanage. Initiatives include fundraising events, awareness campaigns, and collaborations with local businesses and community members. By forging these connections, FOSMO not only elevates its alumni but also enriches the educational and living conditions of the current residents of the orphanage.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
