/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useState, useEffect } from 'react'
import axios from 'axios';

import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import { baseUrl } from 'constants';
import Img1 from 'assets/images/QR_code.png'


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email('Please enter a valid email address')
        .required('Email is required.'),
    password: yup
        .string()
        .required('Please specify your password')
        .min(8, 'The password should have at minimum length of 8'),
});

const occupationChoices = [
    {
        value: 'Farmer',
        label: 'Farmer',
    },
    {
        value: 'Shop Keeper',
        label: 'Shop Keeper',
    },
    {
        value: 'IT',
        label: 'IT',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];

const experienceChoices = [
    {
        value: '1',
        label: 'One Year',
    },
    {
        value: '2',
        label: 'Two Years',
    },
    {
        value: '3',
        label: 'Three Years',
    },
    {
        value: '5',
        label: 'More than 5 years',
    },
];

const qualificationChoices = [
    {
        value: 'Below SSCL',
        label: 'Below SSCL',
    },
    {
        value: 'SSLC',
        label: 'SSLC',
    },
    {
        value: '+2',
        label: 'Plus Two',
    },
    {
        value: 'Degree',
        label: 'Degree',
    },
    {
        value: 'Masters',
        label: 'Masters',
    },
    {
        value: 'Above Masters',
        label: 'Above Masters',
    },
];

const Form = () => {

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "")
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }

        // console.log(cookieValue)
        return cookieValue;
    }

    const [errorText, setErrorText] = useState("")
    const [csrfToken, setCsrfToken] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        address: '',
    })

    const { name, phone, address } = formData

    useEffect(() => {
        var token = getCookie('csrftoken');
        // console.log(csrftoken)
        // getCookie('csrftoken');
        setCsrfToken(token)

    }, [])

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
        // console.log(formData)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setErrorText("")

        if (!name) {
            setErrorText("Name filed is required")
        }
        else if (!phone) {
            setErrorText("Phone filed is required")
        }
        else if (phone.length != 10) {
            console.log("phone is not 10")
            setErrorText("Enter a valid phone number")
        }
        // else if (!email) {
        //     setErrorText("Email filed is required")
        // }
        else if (!address) {
            setErrorText("Address filed is required")
        }
        else {
            // console.log("no error submitting")
            handle_post_member()
        }
    }

    const handle_post_member = () => {
        console.log("submitting frompost", csrfToken)
        // const url = "https://www.fosmowayanad.org/members/create/"
        const url = `${baseUrl}members/create/`

        setLoading(true)
        const token = csrfToken ? csrfToken : "null"

        axios.post(url, {
            name: name,
            phone_number: phone,
            address: address,

        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': token
            }
        },
        )
            .then(function (response) {
                setLoading(false)
                setSuccess(true)
                console.log("sucess in posting", response);
            })
            .catch(function (error) {
                setLoading(false)
                setError(true)
                console.log("error in posting", error);
            });
    }

    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'text.secondary'}
                >
                    Register as a FOSMO Member
                </Typography>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    FOSMO Registration Form
                </Typography>
                <Typography color="text.secondary">
                    For questions on registrations, please contact us at +916235651740
                </Typography>
            </Box>
            {success ?
                <>
                    <Box
                        component={Card}
                        display={'flex'}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                        <CardMedia
                            title={"Membership Fee"}

                            image={Img1}


                            sx={{
                                height: { md: 300, sm: 'auto' },
                                width: { md: 300, sm: 300 },
                            }}
                        />
                        <CardContent>
                            <Box>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    color="text.primary"
                                >
                                    {"Pay Membership Fee"}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    {"Please scan the QR to pay the membership fee and whatsup the admin for confirmation"}
                                </Typography>
                            </Box>
                            <CardActions sx={{ justifyContent: 'flex-start' }}>
                                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                    <Alert severity="success">{"Your registration has  successfully been submitted"}</Alert>
                                </Box>
                            </CardActions>
                        </CardContent>
                    </Box>

                </>
                :
                <>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {/* <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
                                Full Name                        </Typography> */}
                            <TextField
                                label="Full Name *"
                                variant="outlined"
                                label="Full Name"
                                name="name"
                                fullWidth
                                value={name}
                                onChange={onChange}
                            // error={formik.touched.name && Boolean(formik.errors.name)}
                            // helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Box
                                display="flex"
                                flexDirection={{ xs: 'column', sm: 'row' }}
                                alignItems={{ xs: 'stretched', sm: 'center' }}
                                justifyContent={'space-between'}
                                width={1}
                                marginBottom={2}
                            >
                                <Box marginBottom={{ xs: 1, sm: 0 }}>
                                    <Typography variant={'subtitle2'}>
                                        Enter your email
                                    </Typography>
                                </Box>

                            </Box> */}
                            <TextField
                                type="text"
                                variant="outlined"
                                label="Phone Number"
                                name="phone"
                                value={phone}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                onChange={onChange}

                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Box
                                display="flex"
                                flexDirection={{ xs: 'column', sm: 'row' }}
                                alignItems={{ xs: 'stretched', sm: 'center' }}
                                justifyContent={'space-between'}
                                width={1}
                                marginBottom={2}
                            >
                                <Box marginBottom={{ xs: 1, sm: 0 }}>
                                    <Typography variant={'subtitle2'}>
                                        Enter your email
                                    </Typography>
                                </Box>

                            </Box>
                            <TextField
                                type="email"
                                variant="outlined"
                                label="Email"
                                name="email"
                                value={email}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                onChange={onChange}

                            />
                        </Grid> */}
                        {/* <Grid item xs={12}>
                            <TextField
                                type="text"
                                variant="outlined"
                                label="Qualitfication"
                                name="qualification"
                                value={qualification}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                select
                                onChange={onChange}
                            >
                                {qualificationChoices.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid> */}
                        {/* <Grid item xs={12}>
                            <TextField
                                type="text"
                                variant="outlined"
                                label="Occupation"
                                name="occupation"
                                value={occupation}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                select
                                onChange={onChange}
                            >
                                {occupationChoices.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid> */}
                        {/* <Grid item xs={12}>
                            <TextField
                                type="text"
                                variant="outlined"
                                label="Experience"
                                name="experience"
                                value={experience}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                select
                                onChange={onChange}
                            >
                                {experienceChoices.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Address"
                                name="address"
                                value={address}
                                InputLabelProps={{ shrink: true }}
                                multiline
                                fullWidth
                                rows={6}
                                onChange={onChange}
                            />
                        </Grid>

                        {/* submit */}
                        <Grid item container xs={12}>
                            <Box
                                display="flex"
                                flexDirection={{ xs: 'column', sm: 'row' }}
                                alignItems={{ xs: 'stretched', sm: 'center' }}
                                justifyContent={'flex-start'}
                                width={1}
                                maxWidth={600}
                                margin={'0 auto'}
                            >
                                {errorText &&
                                    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                        <Alert severity="error">{errorText}</Alert>
                                    </Box>
                                }

                                {error &&
                                    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                        <Alert severity="error">{"Error Occured, please try again"}</Alert>
                                    </Box>
                                }


                                {loading &&
                                    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                        <CircularProgress />
                                    </Box>
                                }

                                {!success &&
                                    <Box sx={{ display: 'flex', justifyContent: "flex-start", alignItems: "center" }}>
                                        <Button size={'large'} variant={'contained'} onClick={onSubmit}>
                                            Submit
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </>}
        </Box>
    );
};

export default Form;