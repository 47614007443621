// src/features/member/memberSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from "../../constants"

// Async thunk to handle member creation
export const createMember = createAsyncThunk(
    'member/createMember',
    async (memberData, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${baseUrl}members/create/`, memberData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const memberSlice = createSlice({
    name: 'member',
    initialState: {
        member: null,
        loading: false,
        error: null,
    },
    reducers: {
        resetMemberState: (state) => {
            state.member = null;
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createMember.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createMember.fulfilled, (state, action) => {
                state.loading = false;
                state.member = action.payload;
            })
            .addCase(createMember.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { } = memberSlice.actions;
export default memberSlice.reducer;
