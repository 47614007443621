import { configureStore } from '@reduxjs/toolkit'

import eventSlice from 'features/events/eventSlice'
import albumSlice from 'features/album/albumSlice'
import teamSlice from 'features/teams/teamSlice'
import memberSlice from 'features/member/memberSlice'

export const store = configureStore({
    reducer: {
        event: eventSlice,
        album: albumSlice,
        team: teamSlice,
        member: memberSlice
    },
    //   middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware().concat(myCourseApi.middleware, unitApi.middleware, lessonApi.middleware, quizApi.middleware, gameApi.middleware,),
})

