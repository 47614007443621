import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux'
import { getTeams } from 'features/teams/teamSlice'
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';


const Team = () => {

  const teams = useSelector((state) => state.team.data)
  const dispatch = useDispatch()
  const navigate = useNavigate();


  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  React.useEffect(() => {
    dispatch(getTeams())
    console.log(teams)
  }, [])

  const coreTeam = teams?.filter(item => item.category === "CORE");
  const photosToShow = coreTeam ? coreTeam?.slice(0, 3) : []

  return (
    <Box >
      <Box marginBottom={4} paddingTop={2} borderRadius={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Our team
        </Typography>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          VISIONARY LEADERSHIP
        </Typography>
        <Typography align={'center'} sx={{ fontFamily: "Poppins", fontWeight: 200, textAlign: "center" }} variant="h6" color={'text.secondary'}>
          Guiding decisions and shaping our success
        </Typography>
      </Box>
      <Grid container spacing={5} justifyContent="center"
      >
        {photosToShow?.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={1}
              height={1}
              // data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
              component={Card}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              boxShadow={0}
              variant={'outlined'}
              borderRadius={2}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ paddingBottom: 2 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                    <ListItemAvatar sx={{ marginRight: 3 }}>
                      <Avatar
                        src={item.photo?.photo}
                        variant={'rounded'}
                        sx={{ width: 100, height: '90%', borderRadius: 2 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                    />
                  </ListItem>
                </Box>
                <Typography color="text.secondary">{item.feedback}</Typography>
              </CardContent>
            </Box>
          </Grid>
          // <Grid
          //   item
          //   xs={12}
          //   sm={6}
          //   md={3}
          //   key={i}
          //   // data-aos={'fade-up'}
          //   data-aos-delay={i * 100}
          //   data-aos-offset={100}
          //   data-aos-duration={600}
          // >
          //   <Card
          //     sx={{
          //       boxShadow: 0,
          //       background: 'transparent',
          //       backgroundImage: 'none',
          //     }}
          //   >
          //     <Box
          //       component={CardMedia}
          //       borderRadius={2}
          //       width={1}
          //       height={1}
          //       minHeight={320}
          //       image={item.photo?.photo}
          //       marginTop={2}
          //       backgroundColor={"#4a5759"}
          //     />
          //     <Box
          //       component={CardContent}
          //       bgcolor={'transparent'}
          //       marginTop={-5}
          //     >
          //       <Box component={Card}>
          //         <CardContent>
          //           <ListItemText primary={item.name} secondary={item.title} />
          //         </CardContent>
          //       </Box>
          //     </Box>
          //   </Card>
          // </Grid>
        ))}

      </Grid>
      {/* read more */}
      <Box marginTop={4} display={'flex'} justifyContent={"center"} alignSelf={'center'}  >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
          marginTop={2}
          marginBottom={2}
        >
          <Box display="flex" marginTop={{ xs: 2, md: 0 }}>

            <Button onClick={() => navigate("/about")} variant="outlined" color="primary" size="large">
              Find all leaders ...
            </Button>

          </Box>
        </Box>
      </Box>
      {/* read more  */}
    </Box>
  );
};

export default Team;
