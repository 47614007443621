import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from "../../constants"

const initialState = {
    data: null,
    isLoading: false,
    error: null,
};

export const getAlbum = createAsyncThunk('album/fetchAlbum', async () => {
    const response = await axios.get(`${baseUrl}album/`)
    // console.log("getting events", response.data)
    return response.data;
});

const albumSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAlbum.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAlbum.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(getAlbum.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
    },
});

export const { } = albumSlice.actions;
export const data = (state) => state.album.data
export const isLoading = (state) => state.album.isLoading

export default albumSlice.reducer;
