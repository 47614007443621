/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import Img1 from '../../../../assets/images/challenge_1.png'
import Img2 from '../../../../assets/images/QR_code.jpg'


const Overview = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    return (
        <Box
            bgcolor={'primary.main'}
            bgColor="#4527a0"
            borderRadius={2}
            paddingBottom={{ xs: 2, md: 0 }}
            sx={{ backgroundColor: "#4527a0" }}
        >
            <Grid
                container
                // spacing={5}
                direction={{ md: "row", sx: "column" }}
                sx={{ justifyContent: isMd ? "space-between" : "center", alignItems: "center" }}
            >
                <Grid item container xs={12} md={4}
                    justifyContent={{ md: "flex-start", sx: "center" }}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "start",
                    }} >
                    <Box
                        component={'img'}
                        src={Img1}
                        alt="..."
                        sx={{
                            objectFit: 'cover',
                            maxWidth: '90%',
                            width: 250,
                        }}
                    />
                </Grid>
                <Grid item container
                    xs={12} md={4}
                    padddingHorizontal={{ md: 2, sx: 4 }}
                    sx={{
                        alignItems: "center",
                        maxWidth: 300,
                        padddingHorizontal: 2,
                        paddingVertical: 20,
                        justifyContent: isMd ? "center" : "flex-end"
                    }} >
                    <Box marginBottom={isMd ? 1 : 3} marginTop={isMd ? 1 : 3}>
                        <Typography
                            variant="h5"
                            color="text.primary"
                            align={'center'}
                            sx={{ fontWeight: 700, color: 'common.white' }}
                        >
                            ₹ 100 CHALLENGE
                        </Typography>
                        <Typography align={'center'} sx={{ color: 'common.white' }}>
                            The ₹100 CHALLENGE is a unique initiative inviting Fosmo members to contribute just ₹100 every month. Small contributions collectively create a big impact.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item container xs={12} md={4}
                    sx={{ justifyContent: isMd ? "center" : "center", }}>
                    <Box
                        component={'img'}
                        src={Img2}
                        alt="..."
                        sx={{
                            objectFit: 'cover',
                            maxWidth: '60%',
                            borderRadius: 3,
                            parddingHorizontal: 1
                        }}
                    />
                </Grid>
            </Grid>
        </Box >
    );
};

export default Overview;
