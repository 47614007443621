import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from "../../constants"

const initialState = {
    data: null,
    isLoading: false,
    error: null,
};

export const getEvents = createAsyncThunk('progress/fetchProgress', async () => {
    const response = await axios.get(`${baseUrl}events/`)
    // console.log("getting events", response.data)
    return response.data;
});

const eventSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEvents.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(getEvents.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
    },
});

export const { } = eventSlice.actions;
export const data = (state) => state.event.data
export const isLoading = (state) => state.event.isLoading

export default eventSlice.reducer;
