const pages = {
  about: [
    {
      title: 'About',
      href: '/about',

    },

  ],
  contact: [
    {
      title: 'Contact',
      href: '/contact',
    },

  ],
  contact: [
    {
      title: 'Register',
      href: '/register',
    },

  ],
  home: [
    {
      title: 'Home',
      href: '/',
    },
  ],


};

export default pages;
