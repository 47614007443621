import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

import Page from './components/Page';
import Home from "pages/Home";
// import EventDetail from "pages/Detail";
import Contact from "pages/Contact";
import About from "pages/About";
import Register from 'pages/Register'
import { useDispatch } from 'react-redux'
import { getEvents } from 'features/events/eventSlice'


const App = () => {

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getEvents())
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          {/* <Route path="/event/:slug" element={<Detail />} />  */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;