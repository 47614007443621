import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useSelector, useDispatch } from 'react-redux'
import { getAlbum } from 'features/album/albumSlice';

const Gallery = () => {
  const theme = useTheme();
  const dispatch = useDispatch()


  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const album = useSelector((state) => state.album.data)

  React.useEffect(() => {
    // console.log("new page", events)
    dispatch(getAlbum())
  }, [])


  const photosToShow = isMd ? album : album?.slice(0, album?.length - 1);

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Gallery
        </Typography>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 600,
            fontFamily: "Poppins",
            textTransform: 'capitalize',
            marginTop: theme.spacing(1),
          }}
        >
          A VISUAL JOURNEY TO OUR ENDEAVOURS

        </Typography>
        <Typography align={'center'} sx={{ fontFamily: "Poppins", fontWeight: 200, textAlign: "center" }} variant="h6" color={'text.secondary'}>
          A visual journey through our work, showcasing our past achievements and current endeavours.
        </Typography>
      </Box>
      <Box>
        <ImageList
          variant="quilted"
          cols={4}
          rowHeight={isMd ? 300 : 220}
          gap={isMd ? 16 : 8}
        >
          {photosToShow?.map((item, i) => (
            <ImageListItem
              key={i}
              // cols={isMd ? item.cols || 1 : 2}
              // rows={isMd ? item.rows || 1 : 1}
              cols={isMd ? item.width || 1 : 2}
              rows={isMd ? item.hight || 1 : 1}
            >
              <img
                height={'100%'}
                width={'100%'}
                src={item.photo?.photo}
                alt="..."
                loading="lazy"
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {/* read more */}
      <Box display={'flex'} justifyContent={"center"} alignSelf={'center'}  >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
          marginTop={{ xs: 5, md: 8 }}
        >
          <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
            <Button variant="outlined" color="secondary" size="large">
              Find more ...
            </Button>
          </Box>
        </Box>
      </Box>
      {/* read more  */}
    </Box>
  );
};

export default Gallery;
