/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const WhoWeAre = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontFamily: "Poppins", fontWeight: 600 }}>
              OUR INITIATIVES
            </Typography>
            <Typography component={'p'} color={'text.secondary'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>
              At its core, FOSMO seeks to provide aid to its members who are facing hardships. The organisation stands as a lifeline for these individuals, offering not just financial assistance but also emotional support and encouragement. The alumni network is a source of strength; its members come together to share resources, job opportunities, and guidance, thus reinforcing the once-fragile bonds formed during their time at the orphanage.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontFamily: "Poppins", fontWeight: 600 }}>
              OUR ROOTS
            </Typography>
            <Typography component={'p'} color={'text.secondary'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>
              To grasp the essence of FOSMO, we must first acknowledge the unique environment fostered by the Wayanad Muslim Orphanage. Established with the noble intent of providing shelter, education, and emotional support to children from underprivileged backgrounds, the orphanage has become a sanctuary for countless young lives. The alumni of this institution, having overcome personal and societal challenges, found themselves united under the banner of FOSMO.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* read more */}
      <Box display={'flex'} justifyContent={"center"} alignSelf={'center'}  >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
          marginTop={10}
        >
          <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
            <Button onClick={() => navigate("/about")} variant="outlined" color="primary" size="large">
              Know More ...
            </Button>
          </Box>
        </Box>
      </Box>
      {/* read more  */}
    </Box >
  );
};

export default WhoWeAre;
