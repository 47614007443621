/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Img1 from '../../../../assets/images/about_2.jpeg'

const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontFamily: "Poppins", fontWeight: 600 }}>
              OUR STORY
            </Typography>
            <Typography component={'p'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>
              The diverse tapestry of human experiences often includes stories of struggle and resilience. Among them lies the narrative of FOSMO (Forum for Old Students of Muslim Orphanage), an influential alumni organization born from the nurturing grounds of the Wayanad Muslim Orphanage. The primary objective of FOSMO is to offer unwavering support not only to its needy members but also to bolster the very institution that played a pivotal role in shaping their lives—the Wayanad Muslim Orphanage.
            </Typography>
            <br>
            </br>
            <Typography variant={'h4'} gutterBottom sx={{ fontFamily: "Poppins", fontWeight: 600 }}>
              OUR IMPACT
            </Typography>

            <Typography component={'p'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>
              The positive influence of FOSMO extends beyond its members, impacting the larger community in Wayanad. By fostering a culture of philanthropy and social responsibility, FOSMO inspires other alumni groups and organizations to follow suit. The collaboration between FOSMO and the orphanage serves as a model for creating systemic change, encouraging others to invest in their communities. This extends to the creation of mentorship programs, vocational training initiatives, and awareness about the importance of nurturing vulnerable populations.

            </Typography>
            <br>
            </br>

            <Typography component={'p'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>

              The success stories of alumni supported by FOSMO are numerous and varied. From launching small businesses to pursuing higher education, the impact of the organization is tangible. Each success story serves as both a testament to the collective strength of the alumni and a source of encouragement for the younger generations at the orphanage. It reinforces the ethos that they too can rise from their circumstances with hard work, grit, and communal support.


            </Typography>
            <br>
            </br>

            <Typography component={'p'} sx={{ fontFamily: "Poppins", fontWeight: 300 }}>

              Conclusion:
              FOSMO stands as a powerful reminder of the potential that lies within a supportive community. By connecting the past with the present, it honours the legacy of the Wayanad Muslim Orphanage while uplifting those who have traversed its halls. Through its multifaceted approach of aiding needy members and supporting the orphanage, FOSMO encapsulates the spirit of altruism and commitment.

              As FOSMO continues to grow and evolve, it inspires both its members and the broader community to contribute positively, proving that the bonds formed in childhood can indeed last a lifetime. In a world where the needy often remain voiceless, FOSMO shines brightly….

            </Typography>


          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={
                Img1
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
};

export default Story;
