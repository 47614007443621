import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useSelector, useDispatch } from 'react-redux'


const Team = () => {
    const theme = useTheme();
    const teams = useSelector((state) => state.team.data)
    const guardiansTeam = teams?.filter(item => item.category === "EXICUITVE");
    // const coreTeam = teams ? teams?.slice(0, 3) : [
    // console.log(teams)

    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 300,
                        fontFamily: "Poppins"
                    }}
                    gutterBottom
                    color={'text.secondary'}
                    align={'center'}
                >
                    EXECUTIVE
                </Typography>
                <Typography
                    variant="h4"
                    align={'center'}
                    gutterBottom
                    sx={{
                        fontWeight: 600,
                        marginTop: theme.spacing(1),
                        fontFamily: "Poppins"
                    }}
                >
                    EXECUTIVE MEMBERS
                </Typography>
                <Typography sx={{
                    fontFamily: "Poppins", fontWeight: 200
                }} variant="h6" align={'center'} color={'text.secondary'}>
                    Inspiring team to drive our initiatives
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {guardiansTeam?.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box
                            width={1}
                            height={1}
                            component={Card}
                            boxShadow={0}
                            variant={'outlined'}
                            // bgcolor={'alternate.main'}
                            bgcolor={"#e8e8e4"}
                        >
                            <CardContent sx={{ padding: 3 }}>
                                <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                                    <ListItemAvatar sx={{ marginRight: 3 }}>
                                        <Avatar
                                            src={item.photo?.photo}
                                            variant={'rounded'}
                                            sx={{ width: 100, height: 100, borderRadius: 2 }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{ margin: 0, fontFamily: "Poppins", fontWeight: 300 }}

                                        primary={item.name}
                                        secondary={item.title}
                                        primaryTypographyProps={{ variant: 'h6', fontWeight: 700 }}
                                        secondaryTypographyProps={{ variant: 'subtitle1' }}
                                    />
                                </ListItem>
                            </CardContent>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Team;
